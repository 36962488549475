import { Admin, CustomRoutes, Resource } from "react-admin";

import { surveys } from "./surveys";
import { theme } from "./theme";
import { createRestProvider } from "./providers/rest";
import { client } from "./providers/client";
import { authProvider } from "./providers/auth";
import { LoginPage } from "./auth/login";
import { entries } from "./entry";
import { users } from "./users";
import Dashboard from "./dashboard";
import { i18nProvider } from "./i18n";
import { BrowserRouter, Route } from "react-router-dom";
import { ResetPassword } from "./auth/reset";
import { ForgotPassword } from "./auth/forgot";
import { MyLayout } from "./layout";
import { admin, superAdmin } from "./roles";

export const App = () => (
  <BrowserRouter>
    <Admin
      authProvider={authProvider}
      dataProvider={createRestProvider(client)}
      theme={theme}
      loginPage={LoginPage}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      layout={MyLayout}
    >
      {(user) => (
        <>
          {getResources(user).map((resource) => (
            <Resource key={resource.name} {...resource} />
          ))}
          <CustomRoutes noLayout>
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/reset/:token" element={<ResetPassword />} />
          </CustomRoutes>
        </>
      )}
    </Admin>
  </BrowserRouter>
);

function getResources(user: any) {
  if ([superAdmin, admin].includes(user.role)) {
    return [entries, surveys, users];
  }

  return [entries];
}
