import {
  Pagination as RaPagination,
  ListProps,
  ListBase,
  FilterForm,
  FilterButton,
  CreateButton,
} from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import { Box, Stack, Typography } from "@mui/material";

export const ListSurveys = (props: ListProps) => (
  <ListBase
    perPage={50}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
  >
    <Toolbar />
    <MobileListGrid
      metadata={null}
      link={(record: any) => `/surveys/${record.id}/edit`}
      content={(record: any) => <Typography>{record.title}</Typography>}
    />
    <Pagination />
  </ListBase>
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={[]} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={[]} />
        <CreateButton />
      </Box>
    </Stack>
  );
};

const Pagination = () => (
  <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={50} />
);
