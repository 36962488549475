import { client } from "../providers/client";

export function requestResetPasswordLink(email: string) {
  return client
    .post("/auth/reset-password", { email })
    .then((response) => response.data);
}

export function resetPassword(token: string, password: string) {
  return client
    .post("/auth/new-password", { token, password })
    .then((response) => response.data);
}
