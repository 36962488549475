import * as React from "react";
import { Notification } from "react-admin";
import { Paper, Typography, Box } from "@mui/material";
import { theme } from "../theme";
import { ThemeProvider } from "@mui/material";

const pkg = require("../../package.json");

export const AuthLayout: React.FC<{ children: any }> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          padding: 16,
          boxSizing: "border-box",
        }}
      >
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="icon.png"
          alt="Logo"
          height="160"
        />

        <Box paddingTop={"16px"} />

        <Paper
          component={Box}
          padding={4}
          style={{
            width: "100%",
            maxWidth: 420,
            margin: 8,
          }}
        >
          {props.children}
        </Paper>

        <Typography variant="caption" align="center" color="primary">
          v{pkg.version}
        </Typography>
        <Notification />
      </Box>
    </ThemeProvider>
  );
};
