import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { pick, pluck, sum } from "ramda";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  CartesianGrid,
  ReferenceLine,
} from "recharts";

import { ChartContainer } from "./container";

const useStyles = makeStyles({
  root: {
    overflow: "auto",

    "& .recharts-wrapper": {
      position: "relative !important",
    },

    "&::-webkit-scrollbar": { width: "12px" },

    "&::-webkit-scrollbar-corner": { background: "rgba(0, 0, 0, 0)" },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e861a3aa",
      borderRadius: "6px",
      border: "4px solid rgba(0, 0, 0, 0)",
      backgroundClip: "content-box",
      minWidth: "32px",
      minHeight: "32px",
    },

    "&::-webkit-scrollbar-track": { backgroundColor: "rgba(0, 0, 0, 0)" },
  },
});

export const HorizontalBarChart = ({
  request,
  format = (d) => d,
  tickFormat = (d) => d,
  references,
  ...props
}) => {
  const [activeSeries, setActiveSeries] = useState([]);
  const classes = useStyles();
  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { series, stats, category } }) => {
        const activeStats = stats?.sort((a, b) => {
          const aTotal = sum(
            Object.values(pick(pluck("identifier", activeSeries), a))
          );
          const bTotal = sum(
            Object.values(pick(pluck("identifier", activeSeries), b))
          );

          return bTotal - aTotal;
        });

        const height = activeStats?.length ? activeStats?.length * 28 : 360;
        const containerHeight = height > 320 ? height : 320;
        return (
          <>
            <Box height={"320px"} className={classes.root}>
              <ResponsiveContainer width={"100%"} height={containerHeight}>
                <BarChart
                  data={activeStats || []}
                  height={containerHeight}
                  layout="vertical"
                  margin={{ right: 15, left: 15 }}
                  barSize={"10%"}
                  barCategoryGap={1}
                >
                  <YAxis type="category" dataKey={category?.identifier} hide />
                  <CartesianGrid />
                  <Tooltip formatter={format} filterNull />
                  {activeSeries.map((serie, index) => (
                    <Bar
                      key={serie.identifier}
                      name={serie.name}
                      dataKey={serie.identifier}
                      fill={serie.color}
                      isAnimationActive={false}
                      stackId={"a"}
                    >
                      {index === activeSeries.length - 1 ? (
                        <LabelList
                          content={<CustomLabel category={category} />}
                        />
                      ) : null}
                    </Bar>
                  ))}
                  <XAxis
                    type="number"
                    axisLine={false}
                    tickFormatter={tickFormat}
                    orientation="top"
                  />
                  {references?.map((reference) => (
                    <ReferenceLine
                      key={reference.name}
                      x={reference.value}
                      label={reference.name}
                      stroke={reference.color}
                      opacity={0.8}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Legend series={series} onChange={setActiveSeries} />
          </>
        );
      }}
    </ChartContainer>
  );
};

const CustomLabel = (props) => {
  const { y, height, category } = props;
  const name = props[category.identifier];
  const fontSize = height > 20 ? 12 : height / 2;
  return (
    <text
      x={24}
      y={y + height / 2}
      fontFamily="sans-serif"
      fontSize={fontSize}
      fill={"#fff"}
      textAnchor="left"
      dominantBaseline="middle"
      paintOrder="stroke"
      stroke="#444"
      strokeWidth="2px"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      fontWeight={"800"}
    >
      {name}
    </text>
  );
};

export const Legend = ({ series, onChange }) => {
  const [active, setActive] = useState([]);

  useEffect(() => {
    if (active.length > 0) {
      onChange(
        series
          .filter((serie) => !serie.hide)
          .filter((serie) => active.includes(serie.identifier))
      );
    } else {
      onChange(series.filter((serie) => !serie.hide));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, series]);
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      flexWrap={"wrap"}
      marginX={1}
      marginY={1}
    >
      {series.length > 1
        ? series
            .filter((serie) => !serie.hide && serie.type !== "label")
            .map((serie, i) => (
              <Box
                display={"flex"}
                alignItems="center"
                key={i}
                bgcolor={serie.color}
                padding={"0 4px"}
                marginRight={"4px"}
                marginBottom={"4px"}
                onClick={() =>
                  setActive((v) =>
                    v.includes(serie.identifier)
                      ? v.filter((x) => x !== serie.identifier)
                      : [...v, serie.identifier]
                  )
                }
              >
                <Typography variant="caption" style={{ color: "white" }}>
                  {serie.name}
                </Typography>

                <Box
                  bgcolor={
                    active.includes(serie.identifier) ? "white" : serie.color
                  }
                  marginLeft={1}
                  width={12}
                  height={12}
                  borderRadius={12}
                />
              </Box>
            ))
        : null}
    </Box>
  );
};
