import polyglotI18nProvider from "ra-i18n-polyglot";
import raEnglish from "ra-language-english";
import { resolveBrowserLocale } from "react-admin";

const messages: Record<string, any> = {
  en: {
    ...raEnglish,
    ra: {
      ...raEnglish.ra,
      action: {
        ...raEnglish.ra.action,
        add_filter: "Filter",
      },
    },
  },
};

export const availableLocales = [{ locale: "en", name: "English" }];

export const i18nProvider = polyglotI18nProvider(
  (locale: string) => messages[locale],
  resolveBrowserLocale("en"),
  availableLocales,
  {
    allowMissing: false,
    onMissingKey: (key: any) => key,
  }
);
