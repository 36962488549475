import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  List,
  CircularProgress,
  Box
} from "@mui/material";
import { roleMapper } from "../../roles";
import { ChartContainer } from "./container";
import { Conditionally } from "../../components/conditionally";

export const Leaderboard = props => {
  return (
    <ChartContainer
      defaultState={{
        series: [],
        stats: []
      }}
      {...props}
    >
      {({ value, loading }) => {
        return (
          <>
            <Conditionally
              when={value.stats.length > 0 && !loading}
              render={() => (
                <List style={{ height: 230, overflowY: "auto" }}>
                  {value.stats.map((item, i) => {
                    const { name, count, role } = item;
                    return (
                      <ListItem key={i}>
                        <ListItemAvatar>
                          <Avatar>{name?.toUpperCase().charAt(0)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={name}
                          secondary={roleMapper?.[role]}
                        />
                        <ListItemSecondaryAction>
                          <ListItemText primary={count} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            />

            <Conditionally
              render={() => (
                <Box
                  height={"230px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <CircularProgress />
                </Box>
              )}
              when={loading}
            />

            <Conditionally
              render={() => (
                <Box
                  height={"230px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography variant={"body1"}>
                    No data for this period.
                  </Typography>
                </Box>
              )}
              when={!loading && !value.stats.length}
            />
          </>
        );
      }}
    </ChartContainer>
  );
};
