import * as React from "react";
import { Link, useNotify } from "react-admin";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { AuthLayout } from "./layout";
import { useRequestState } from "../hooks/request";
import { requestResetPasswordLink } from "../users/operations";

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const forgot = useRequestState(requestResetPasswordLink, null);
  const notify = useNotify();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    forgot
      .fetch(email)
      .then((data) => {
        if (data) notify(data.message);
      })
      .catch((error) => {
        notify(error.response?.data?.message || error.message);
      });
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <Box marginY="1em">
          <TextField
            autoFocus
            name="email"
            label={"Email"}
            value={email}
            fullWidth
            onChange={(event) => setEmail(event.target.value)}
          />
        </Box>

        <Button variant="contained" type="submit" color="primary" fullWidth>
          {forgot.loading && <CircularProgress size={25} thickness={2} />}
          Request Reset Link
        </Button>

        <Box marginTop="1em" />

        <Typography
          component={Link}
          to="/login"
          align="center"
          color="secondary"
        >
          Back to Login
        </Typography>
      </form>
    </AuthLayout>
  );
};
