import {
  Create,
  CreateProps,
  Edit,
  EditProps,
  RaRecord,
  SaveHandler,
} from "react-admin";
import {
  Chip,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography,
  Autocomplete,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useState } from "react";
import {
  useCreateController,
  CreateContextProvider,
  useEditController,
  EditContextProvider,
  Button as RaButton,
} from "react-admin";
import {
  Save,
  ArrowUpward,
  ArrowDownward,
  Close,
  Add,
} from "@mui/icons-material";
import { uniq } from "ramda";
import { questionFormats, selectionFields } from "./constants";

export const CreateSurvey = (props: CreateProps) => {
  const createControllerProps = useCreateController(props);
  const { save, record } = createControllerProps;
  return (
    <Create {...props} title={<CreateTitle />}>
      <CreateContextProvider value={createControllerProps}>
        <Form record={record} save={save} />
      </CreateContextProvider>
    </Create>
  );
};

export const EditSurvey = (props: EditProps) => {
  const editControllerProps = useEditController(props);
  const { save, record } = editControllerProps;
  return (
    <Edit {...props} title={"Survey Details"}>
      <EditContextProvider value={editControllerProps}>
        <Form record={record} save={save} />
      </EditContextProvider>
    </Edit>
  );
};

const Form: React.FC<{
  record?: RaRecord<string> | any;
  save?: SaveHandler<any>;
}> = ({ record = {}, save }) => {
  const [title, setTitle] = useState(record.title || "");
  const [description, setDescription] = useState(record.description || "");
  const [questions, setQuestions] = useState<any[]>(record.questions || []);

  function handleSave() {
    save?.({ title, description, questions });
  }

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              label="Name"
              variant="outlined"
              type="text"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Description"
              variant="outlined"
              type="text"
              fullWidth
              multiline
              minRows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Questions value={questions} onChange={setQuestions} />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <RaButton
          variant="contained"
          color="primary"
          label="Save"
          size="medium"
          onClick={handleSave}
        >
          <Save color="inherit" />
        </RaButton>
      </CardActions>
    </Card>
  );
};

const Questions: React.FC<{ value: any[]; onChange(value: any[]): void }> = ({
  value,
  onChange,
}) => {
  function remove(position: number) {
    return () =>
      onChange(
        value
          .filter((qn) => qn.position !== position)
          .map((qn, i) => ({ ...qn, position: i + 1 }))
      );
  }

  function handleMoveUp(position: number) {
    return () =>
      onChange(
        value
          .map((qn) => {
            if (qn.position === position - 1) {
              return { ...qn, position: position };
            }

            if (qn.position === position) {
              return { ...qn, position: position - 1 };
            }

            return qn;
          })
          .sort((a, b) => a.position - b.position)
      );
  }

  function handleMoveDown(position: number) {
    return () =>
      onChange(
        value
          .map((qn) => {
            if (qn.position === position) {
              return { ...qn, position: position + 1 };
            }

            if (qn.position === position + 1) {
              return { ...qn, position };
            }

            return qn;
          })
          .sort((a, b) => a.position - b.position)
      );
  }

  function handleChange(position: number) {
    return (record: any) => {
      onChange(
        value.map((qn) => {
          if (qn.position === position) {
            return record;
          }

          return qn;
        })
      );
    };
  }

  function handleAdd() {
    onChange([...value, { position: value.length + 1 }]);
  }

  return (
    <Card>
      <CardHeader title={<Typography>Questions</Typography>} />
      <CardContent>
        <Grid container direction="column">
          {value.length > 0
            ? value.map((qn) => {
                return (
                  <Grid key={qn.position} item>
                    <Question
                      question={qn}
                      all={value}
                      remove={remove(qn.position)}
                      onChange={handleChange(qn.position)}
                      moveDown={handleMoveDown(qn.position)}
                      moveUp={handleMoveUp(qn.position)}
                    />
                  </Grid>
                );
              })
            : null}
        </Grid>
      </CardContent>
      <CardActions>
        <Button startIcon={<Add />} onClick={handleAdd}>
          Add Question
        </Button>
      </CardActions>
    </Card>
  );
};

const Question: React.FC<{
  question: any;
  all: any[];
  onChange: any;
  moveUp: any;
  moveDown: any;
  remove: any;
}> = ({ onChange, question, all, moveUp, moveDown, remove }) => {
  function handleChange(key: string, value: any) {
    onChange({ ...question, [key]: value });
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        borderColor: "#efefef",
        borderRadius: 16,
        padding: "16px 0",
      }}
    >
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Chip label={question.position} color="primary" />
          </Grid>
          {question.position === 1 ? null : (
            <Grid item>
              <IconButton onClick={moveUp} size="small">
                <ArrowUpward />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton onClick={remove} size="small">
              <Close />
            </IconButton>
          </Grid>
          {question.position === all.length ? null : (
            <Grid item>
              <IconButton onClick={moveDown} size="small">
                <ArrowDownward />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <TextField
              label="Id"
              variant="outlined"
              fullWidth
              value={question.id || ""}
              onChange={(e) =>
                handleChange(
                  "id",
                  e.target.value.replace(/\s+/, "").toLocaleLowerCase()
                )
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={question.title || ""}
              onChange={(e) => handleChange("title", e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Format</InputLabel>
              <Select
                label="Format"
                value={question.format || ""}
                onChange={(e) => handleChange("format", e.target.value)}
                fullWidth
                placeholder="Format"
              >
                {questionFormats.map((format) => (
                  <MenuItem key={format.id} id={format.id} value={format.id}>
                    {format.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {selectionFields.includes(question.format) ? (
            <Grid item>
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                options={[]}
                value={question.options || []}
                onChange={(_, value) => handleChange("options", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Options"
                    placeholder="Options"
                    variant="outlined"
                    size="medium"
                    onPaste={(e) => {
                      e.preventDefault();

                      handleChange(
                        "options",
                        (question.options || []).concat(
                          uniq(
                            e.clipboardData
                              .getData("text/plain")
                              .split(/\s*,\s*/)
                          )
                        )
                      );
                    }}
                  />
                )}
              />
            </Grid>
          ) : null}

          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={question.showOnListing || false}
                    onChange={(_, checked) =>
                      handleChange("showOnListing", checked)
                    }
                  />
                }
                label="Display on List"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreateTitle = () => {
  return <Typography variant="h6">Create Survey</Typography>;
};
