import { ContactSupport } from "@mui/icons-material";

import { ListSurveys } from "./list";
import { CreateSurvey, EditSurvey } from "./create-edit";

export const surveys = {
  name: "surveys",
  options: {
    label: "Surveys",
  },
  icon: ContactSupport,
  list: ListSurveys,
  create: CreateSurvey,
  edit: EditSurvey,
};
