import { LayoutProps, Menu, usePermissions } from "react-admin";
import { Layout } from "react-admin";

export const MyLayout: React.FC<LayoutProps> = (props) => (
  <Layout {...props} menu={MyMenu} />
);

export const MyMenu = () => {
  const permissions = usePermissions();
  return (
    <Menu>
      {permissions?.permissions?.role !== "surveyor" ? (
        <Menu.DashboardItem />
      ) : null}
      <Menu.ResourceItems />
    </Menu>
  );
};
