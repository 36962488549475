import { AddCard } from "@mui/icons-material";

import { ListEntries } from "./list";
import { CreateEntry, EditEntry } from "./create-edit";

export const entries = {
  name: "entries",
  options: {
    label: "Entries",
  },
  icon: AddCard,
  list: ListEntries,
  create: CreateEntry,
  edit: EditEntry,
};
