import { Box, Typography } from "@mui/material";
import React from "react";
import { PieChart as RePieChart, Pie, Cell, Tooltip } from "recharts";

import { ChartContainer } from "./container";

import "./pie.css";

export const PieChart = ({ request, format = (d) => d, ...props }) => {
  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { series } }) => {
        const stats = series.filter((d) => !d.hide);
        return (
          <Box display={"flex"} flexDirection="row" alignItems={"center"} marginBottom={1}>
            <Box width={"96px"} height={"96px"} marginLeft={1} marginRight={2} flexShrink={0}>
              <RePieChart width={96} height={96}>
                <Pie
                  dataKey="value"
                  nameKey="name"
                  data={stats}
                  outerRadius={48}
                  innerRadius={24}
                >
                  {stats.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip formatter={format} />
              </RePieChart>
            </Box>

            <Box display={"flex"} flexDirection="column" flexGrow={1}>
              <Box
                display={"flex"}
                flexDirection="row"
                flexWrap={"wrap"}
                marginBottom={1}
              >
                {series
                  .filter((serie) => serie.hide)
                  .map((serie, i) => (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      key={i}
                      flexGrow={1}
                      marginBottom={"4px"}
                    >
                      <Typography
                        variant={!serie.name ? "body1" : "h6"}
                        style={{ fontWeight: 500, lineHeight: 1 }}
                      >
                        {serie.value}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {serie.name}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              <Box
                display={"flex"}
                flexDirection="row"
                flexWrap={"wrap"}
                justifyContent="space-around"
              >
                {series
                  .filter((serie) => !serie.hide)
                  .map((serie, i) => (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      key={i}
                      flexGrow={1}
                      flexBasis={"25%"}
                      marginBottom={"4px"}
                    >
                      <Typography
                        variant={"caption"}
                        style={{ fontWeight: 500, lineHeight: 1 }}
                      >
                        {format(serie.value)}
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection="row"
                        alignItems={"center"}
                      >
                        <Box
                          bgcolor={serie.color}
                          width={"8px"}
                          height={"8px"}
                          borderRadius={"8px"}
                          display="flex"
                          marginRight={"4px"}
                        />
                        <Typography variant="caption" color="textSecondary">
                          {serie.name}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        );
      }}
    </ChartContainer>
  );
};
