import {
  AppBar,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Pagination as RaPagination,
  ReferenceInput,
  AutocompleteInput,
  useListContext,
  FilterButton,
  useDataProvider,
  FilterForm,
  ListBase,
  TextInput,
  NumberInput,
  Form,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  SaveButton,
} from "react-admin";
import { selectionFields } from "../surveys/constants";
import moment from "moment";
import { MobileListGrid } from "../components/mobile-list-grid";
import {
  Add,
  Close,
  Keyboard,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Timer,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useWatch, useFormContext } from "react-hook-form";
import { useRequestState } from "../hooks/request";
import { Conditionally } from "../components/conditionally";
import { Question, QuestionsInput } from "./question-grid";

export const ListEntries = () => {
  return (
    <ListBase perPage={50} sort={{ field: "createdAt", order: "DESC" }}>
      <ListToolbar />
      <MobileListGrid
        metadata={(record: any) => (
          <Typography variant="caption" color={"primary"}>
            {record.survey.title} - {moment(record.createdAt).fromNow()}
          </Typography>
        )}
        link={(record: any) => `/entries/${record.id}/edit`}
        content={(record: any) => (
          <Box paddingBlock={"4px"}>
            <Grid container spacing={1}>
              {Object.keys(record?.answers || {}).map((key) => (
                <Grid item key={key}>
                  <Chip size="small" label={record?.answers?.[key]} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      />
      <Dial />
      <Pagination />
    </ListBase>
  );
};

const ListToolbar = () => {
  const [questions, setQuestions] = useState<any>([]);
  const context = useListContext();
  const provider = useDataProvider();

  useEffect(() => {
    if (!context.filterValues?.surveyId) {
      return setQuestions([]);
    }
    provider
      .getOne("surveys", { id: context.filterValues?.surveyId })
      .then(({ data }) => {
        setQuestions(data.questions);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.filterValues?.surveyId]);

  const filters = [
    <ReferenceInput reference="surveys" source={"surveyId"}>
      <AutocompleteInput optionText="title" size="small" fullWidth />
    </ReferenceInput>,
    ...questions
      .map((question: any) => {
        if (selectionFields.includes(question.format)) {
          return (
            <AutocompleteInput
              label={question.title}
              source={`answers.${question.id}`}
              choices={question.options.map((choice: any) => ({
                id: choice,
                name: choice,
              }))}
            />
          );
        }

        if (["Text"].includes(question.format)) {
          return (
            <TextInput
              label={question.title}
              source={`search.answers.${question.id}`}
            />
          );
        }

        if (["Number"].includes(question.format)) {
          return (
            <NumberInput
              label={question.title}
              source={`answers.${question.id}`}
            />
          );
        }

        return null;
      })
      .filter((d: any) => d),
  ];
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={filters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={filters} />
      </Box>
    </Stack>
  );
};

const CreateSession: React.FC<any> = ({ open, onStart, onCancel }) => {
  function saveSession(data: any) {
    const sessions = JSON.parse(localStorage.getItem("sessions") || "[]");
    localStorage.setItem("sessions", JSON.stringify([...sessions, data]));
    onStart(data);
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>New Data Entry Session</DialogTitle>
      <DialogContent>
        <Form onSubmit={saveSession} sanitizeEmptyValues>
          <ReferenceInput source="surveyId" reference="surveys">
            <RadioButtonGroupInput optionText={"title"} />
          </ReferenceInput>
          <PrefillFormInput />
          <Box display={"flex"} style={{ justifyContent: "space-between" }}>
            <Button startIcon={<Close />} onClick={() => onCancel()}>
              Cancel
            </Button>
            <SaveButton
              type="submit"
              variant="contained"
              icon={<div />}
              label="Start"
              endIcon={<KeyboardArrowRight />}
            />
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const Session: React.FC<any> = ({ session, onEnd }) => {
  const provider = useDataProvider();

  if (!session) {
    return null;
  }

  function saveAndNext(data: any) {
    provider.create("entries", { data });
  }

  return (
    <Dialog open={true} fullScreen>
      <AppBar>
        <Toolbar component={Box} justifyContent={"space-around"}>
          <Typography>
            {Object.values(session.answers).filter(Boolean).join(" - ")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box marginTop={10} />
      <Container>
        <Form
          sanitizeEmptyValues
          defaultValues={{
            surveyId: session.surveyId,
            answers: session.answers,
          }}
        >
          <Card>
            <CardContent>
              <QuestionsInput hide={session.questions} />
            </CardContent>
            <CardActions style={{ justifyContent: "space-between" }}>
              <Button startIcon={<Close />} onClick={() => onEnd()}>
                End Session
              </Button>
              <SessionSaveButton onSave={saveAndNext} />
            </CardActions>
          </Card>
        </Form>
      </Container>
    </Dialog>
  );
};

const SessionSaveButton: React.FC<any> = ({ onSave }) => {
  const { handleSubmit, formState, reset } = useFormContext();
  const onSubmit = handleSubmit(onSave);

  return (
    <SaveButton
      onClick={(e) => {
        return onSubmit(e).then(() => {
          if (Object.values(formState.errors).length === 0) {
            reset();
          }
        });
      }}
      variant="contained"
      icon={<div />}
      endIcon={<KeyboardArrowRight />}
      disabled={formState.isSubmitting}
      label="Next"
    />
  );
};

const PrefillFormInput = () => {
  const provider = useDataProvider();
  const survey = useRequestState((id) =>
    provider.getOne("surveys", { id }).then((response) => response.data)
  );
  const surveyId = useWatch({ name: "surveyId" });
  const questions = useWatch({ name: "questions" });

  useEffect(() => {
    if (surveyId) survey.fetch(surveyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  return (
    <Conditionally
      when={surveyId}
      render={() => (
        <>
          <CheckboxGroupInput
            source="questions"
            label="Which questions have the same answers in this session?"
            choices={
              survey?.value?.questions.map((q: any) => ({
                id: q.id,
                name: q.title,
              })) || []
            }
          />

          {survey?.value?.questions
            ?.filter((q: any) => questions?.includes(q.id))
            ?.map((q: any) => (
              <Question key={q.id} question={q} />
            ))}
        </>
      )}
    />
  );
};

const Dial = () => {
  const [create, setCreate] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setSessions(JSON.parse(localStorage.getItem("sessions") || "[]"));
  }, []);

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon openIcon={<KeyboardArrowUp />} />}
      >
        {sessions
          .reverse()
          .slice(-3)
          .map((session: any, i) => (
            <SpeedDialAction
              icon={<Keyboard />}
              tooltipTitle={Object.values(session.answers || {})
                .filter(Boolean)
                .join(" ")}
              onClick={() => setSession(session)}
              key={i}
            />
          ))}
        <SpeedDialAction
          icon={<Timer />}
          tooltipTitle={"Start Session"}
          onClick={() => setCreate(true)}
        />
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle={"New Entry"}
          onClick={() => navigate("/entries/create")}
        />
      </SpeedDial>
      <CreateSession
        open={create}
        onStart={(session: any) => {
          setCreate(false);
          setSession(session);
        }}
        onCancel={() => setCreate(false)}
      />
      <Session session={session} onEnd={() => setSession(null)} />
    </>
  );
};

const Pagination = () => (
  <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={50} />
);
