import {
  Create,
  CreateProps,
  Edit,
  EditProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { Typography } from "@mui/material";
import { QuestionsInput } from "./question-grid";

export const CreateEntry = (props: CreateProps) => {
  return (
    <Create {...props} title={<CreateTitle />}>
      <Form />
    </Create>
  );
};

export const EditEntry = (props: EditProps) => {
  return (
    <Edit {...props} title="Edit Entry">
      <Form />
    </Edit>
  );
};

const Form: React.FC = () => {
  return (
    <SimpleForm>
      <ReferenceInput reference="surveys" source={"surveyId"}>
        <SelectInput optionText="title" fullWidth />
      </ReferenceInput>
      <QuestionsInput />
    </SimpleForm>
  );
};

const CreateTitle = () => {
  return <Typography variant="h6">New Entry</Typography>;
};
