import { Grid } from "@mui/material";
import React, { useState } from "react";
import {
  ResponsiveContainer,
  AreaChart as ReAreaChart,
  // LineChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  // Line,
} from "recharts";
import { ChartContainer } from "./container";
import moment from "moment";
import { Legend } from "./horizontal-bar";

export const TrendAreaChart = ({
  hideYAxis,
  defaultStep = "day",
  format = (d) => d,
  tickFormat = (d) => d,
  domain,
  references = [],
  ...props
}) => {
  const [activeSeries, setActiveSeries] = useState([]);
  return (
    <ChartContainer
      {...props}
      defaultStep={defaultStep}
      defaultState={{
        series: [],
        stats: [],
        period: {},
      }}
    >
      {({ value: { series, stats, period } }) => {
        return (
          <>
            <Grid item>
              <ResponsiveContainer width={"100%"} height={240}>
                <ReAreaChart data={stats}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey="date"
                    domain={[() => new Date(period.start).getTime(), "dataMax"]}
                    name="Time"
                    tickFormatter={(unixTime) =>
                      moment(unixTime).format("Do MMM")
                    }
                    type="number"
                    scale="time"
                  />
                  <YAxis
                    type="number"
                    width={0.1}
                    tickLine={false}
                    tickFormatter={tickFormat}
                    tick={<Tick />}
                    domain={domain}
                    hide={hideYAxis}
                    axisLine={false}
                  />
                  <Tooltip
                    labelFormatter={(unixTime) =>
                      moment(unixTime).format("Do MMM YYYY")
                    }
                    formatter={format}
                  />

                  {activeSeries.map((indicator) => (
                    <Area
                      key={indicator.identifier}
                      type="basis"
                      dataKey={indicator.identifier}
                      fill={indicator.color}
                      name={indicator.name}
                      stackId="a"
                      stroke={indicator.color}
                    />
                  ))}
                  {references.map((reference) => (
                    <ReferenceLine
                      key={reference.name}
                      y={reference.value}
                      label={reference.name}
                      stroke={reference.color}
                      opacity={0.8}
                    />
                  ))}
                </ReAreaChart>
              </ResponsiveContainer>
            </Grid>
            <Legend
              series={series}
              onChange={setActiveSeries}
            />
          </>
        );
      }}
    </ChartContainer>
  );
};

const Tick = (props) => {
  const { y, verticalAnchor, payload, tickFormatter } = props;
  if (payload.value === 0) {
    return <g></g>;
  }

  return (
    <g>
      <text
        x={10}
        fill={"#555"}
        textAnchor={"start"}
        dominantBaseline={verticalAnchor}
        y={y}
        fontSize={12}
      >
        {tickFormatter(payload.value)}
      </text>
    </g>
  );
};
