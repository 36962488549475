import jwtDecode from "jwt-decode";
import { client } from "./client";
import { AuthProvider } from "react-admin";

export const authProvider: AuthProvider = {
  login(params) {
    return client
      .post(`/auth/login`, params)
      .then((response) => response.data)
      .then((token) => {
        localStorage.setItem("token", token);
      });
  },
  checkError(error) {
    if (error.status === 401) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth() {
    const token = localStorage.getItem("token");

    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  logout() {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  getIdentity() {
    const token = localStorage.getItem("token");

    if (!token) {
      return Promise.reject();
    }

    const user: any = jwtDecode(token);

    if (!user) {
      return Promise.reject();
    }

    const { id, name, avatar } = user;

    const [fullName] = name.split(/\s+/);

    return Promise.resolve({ id, fullName, avatar });
  },
  getPermissions() {
    const user = getUser();
    const isResetOrForgot = /(forgot|reset|passcode)/.test(
      window.location.pathname
    );

    if (!user && !isResetOrForgot) {
      return Promise.reject({});
    }

    return Promise.resolve(user || {});
  },
};

function getUser() {
  const token = localStorage.getItem("token");

  if (!token) return;

  const user = jwtDecode(token);

  return user;
}
