import React from "react";

interface Props {
  when: boolean;
  render(): React.ReactNode;
}

export const Conditionally: React.FC<Props> = ({ when, render }) => {
  return <>{!!when ? render() : null}</>;
};
