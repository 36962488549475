import { Grid } from "@mui/material";
import { Fragment, useEffect } from "react";
import {
  AutocompleteInput,
  CheckboxGroupInput,
  DateInput,
  DateTimeInput,
  NumberInput,
  RadioButtonGroupInput,
  TextInput,
  required,
  useDataProvider,
} from "react-admin";
import { useRequestState } from "../hooks/request";
import { useWatch } from "react-hook-form";

export const QuestionsInput: React.FC<any> = ({ hide = [] }) => {
  const provider = useDataProvider();
  const surveyId = useWatch({ name: "surveyId" });

  const survey = useRequestState((id: string) => {
    return provider.getOne("surveys", { id }).then((result) => result.data);
  });

  useEffect(() => {
    if (surveyId) {
      survey.fetch(surveyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  return (
    <>
      <Grid direction="column" container>
        <Grid item>
          {survey?.value?.questions
            ?.filter((q: any) => !hide.includes(q.id))
            ?.map((question: any) => (
              <Question question={question} />
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export const Question: React.FC<any> = ({ question }) => {
  return (
    <Fragment key={question.id}>
      {question.format === "Checkboxes" ? (
        <CheckboxGroupInput
          source={`answers.${question.id}`}
          choices={question.options.map((id: string) => ({
            id,
            name: id,
          }))}
          label={question.title}
          fullWidth
          validate={[required()]}
          row={false}
        />
      ) : null}

      {question.format === "Radio Buttons" ? (
        <RadioButtonGroupInput
          source={`answers.${question.id}`}
          choices={question.options.map((id: string) => ({
            id,
            name: id,
          }))}
          label={question.title}
          fullWidth
          validate={[required()]}
          row
        />
      ) : null}

      {question.format === "Text" ? (
        <TextInput
          source={`answers.${question.id}`}
          label={question.title}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          validate={[required()]}
        />
      ) : null}

      {question.format === "Autocomplete" ? (
        <AutocompleteInput
          source={`answers.${question.id}`}
          label={question.title}
          TextFieldProps={{
            InputLabelProps: {
              shrink: true,
            },
          }}
          fullWidth
          variant="outlined"
          choices={question.options.map((id: string) => ({ id, name: id }))}
          validate={[required()]}
        />
      ) : null}

      {question.format === "Multiline Text" ? (
        <TextInput
          multiline
          source={`answers.${question.id}`}
          label={question.title}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          validate={[required()]}
        />
      ) : null}

      {question.format === "Number" ? (
        <NumberInput
          source={`answers.${question.id}`}
          label={question.title}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          validate={[required()]}
        />
      ) : null}

      {question.format === "Dropdowns" ? <div /> : null}

      {question.format === "Date" ? (
        <DateInput
          source={`answers.${question.id}`}
          label={question.title}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          validate={[required()]}
        />
      ) : null}

      {question.format === "Date Time" ? (
        <DateTimeInput
          source={`answers.${question.id}`}
          label={question.title}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          validate={[required()]}
        />
      ) : null}

      {question.format === "Time" ? (
        <DateTimeInput
          source={`answers.${question.id}`}
          label={question.title}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          validate={[required()]}
        />
      ) : null}
    </Fragment>
  );
};
