import { createTheme } from "@mui/material";
import { houseLightTheme } from "react-admin";

export const primaryColor = "#d05286";
export const secondaryColor = "#572345";

export const theme = createTheme({
  ...houseLightTheme,
  palette: {
    mode: "light",
    primary: {
      main: primaryColor,
      light: primaryColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#fff",
    },
    background: {
      paper: "#ffffff",
      default: "#fafafa",
    },
  },
  components: {
    ...houseLightTheme.components,
    ...({
      RaAppBar: {
        styleOverrides: {
          root: {
            color: primaryColor,
            "& .RaAppBar-toolbar": {
              backgroundColor: primaryColor,
              color: houseLightTheme.palette.background.default,
              backgroundImage: `linear-gradient(310deg, ${secondaryColor}, ${primaryColor})`,
            },
          },
        },
      },

      RaMenuItemLink: {
        styleOverrides: {
          root: {
            padding: 10,
            marginRight: 10,
            marginLeft: 10,
            "&:hover": {
              borderRadius: 5,
            },
            "&.RaMenuItemLink-active": {
              borderRadius: 10,
              backgroundColor: houseLightTheme.palette.common.white,
              color: houseLightTheme.palette.primary.main,
              "&:before": {
                content: '""',
                position: "absolute",
                top: "0; right: 0; bottom: 0; left: 0",
                zIndex: "-1",
                margin: "-2px",
                borderRadius: "12px",
                background: `linear-gradient(310deg, ${secondaryColor}, ${primaryColor})`,
              },
              "& .MuiSvgIcon-root": {
                fill: secondaryColor,
              },
            },
          },
        },
      },
    } as any),
  },
});
