import React, { useRef, useCallback, useState } from "react";
import Map, { Source, Layer } from "react-map-gl";
import { Box, Typography } from "@mui/material";

import { ChartContainer } from "./container";
import { Conditionally } from "../../components/conditionally";

export const MapChart = (props) => {
  const mapRef = useRef();
  const [hoverInfo, setHoverInfo] = useState(null);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];

    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
  }, []);

  return (
    <ChartContainer
      {...props}
      defaultState={{
        series: [],
        stats: [],
        period: {},
      }}
      onChange={({ bbox }) => mapRef.current?.fitBounds(bbox)}
    >
      {({ value: { collection, bbox, layers, legend, field } }) => {
        if (!collection) {
          return <div style={{ width: "100%", height: "80vh" }} />;
        }

        return (
          <>
            <Map
              ref={mapRef}
              style={{ width: "100%", height: "80vh" }}
              mapboxAccessToken={
                "pk.eyJ1IjoiZXJuZXN0b28iLCJhIjoiY2w1azhydHBoMDdwZTNvbnhndHpybHk3OSJ9.iKMRDef7z1SaYsCT4Z2skQ"
              }
              mapStyle="mapbox://styles/ernestoo/cl5kmqzko008i14nvefbcudzo"
              onMouseMove={onHover}
              interactiveLayerIds={layers
                .filter((l) => ["point", "area"].includes(l.id))
                .map((l) => l.id)}
              onLoad={() => {
                mapRef.current?.fitBounds(bbox);
              }}
            >
              <Source type="geojson" data={collection}>
                {layers.map((layer) => (
                  <Layer key={layer.id} {...layer} />
                ))}
              </Source>

              <Legend {...legend} />

              {hoverInfo && (
                <Box
                  className="tooltip"
                  position={"absolute"}
                  left={hoverInfo.x + 16}
                  top={hoverInfo.y + 16}
                  padding={2}
                  style={{ backgroundColor: "grey", color: "white" }}
                >
                  <Typography variant="body1">
                    {hoverInfo.feature.properties.name}
                  </Typography>
                  <Typography variant="caption">
                    {hoverInfo.feature.properties[field]}
                  </Typography>
                </Box>
              )}
            </Map>
          </>
        );
      }}
    </ChartContainer>
  );
};

export const Legend = ({ type, values, range, colors }) => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      position={"absolute"}
      left={"1em"}
      top={"1em"}
      padding={1}
      style={{ background: "white" }}
    >
      <Conditionally
        when={type === "discrete"}
        render={() => {
          return values.map((value) => (
            <Box display={"flex"} padding={"4px"} alignItems={"center"}>
              <Box
                style={{ backgroundColor: value.color }}
                borderRadius={"1em"}
                width={"1em"}
                height={"1em"}
                marginRight={"4px"}
              />
              <Typography variant="caption">{value.label}</Typography>
            </Box>
          ));
        }}
      />
      <Conditionally
        when={type === "continous"}
        render={() => {
          return (
            <Box display={"flex"} padding={"4px"} alignItems={"center"}>
              <Typography variant="caption">{range[0]}</Typography>
              <Box
                style={{
                  backgroundImage: `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`,
                }}
                width={"8em"}
                height={"1em"}
                marginX={"4px"}
              />
              <Typography variant="caption">{range[1]}</Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};
