import * as React from "react";
import { useNotify } from "react-admin";
import { Box, Button, CircularProgress, TextField } from "@mui/material";

import { AuthLayout } from "./layout";
import { useRequestState } from "../hooks/request";
import { resetPassword } from "../users/operations";
import { useNavigate, useParams } from "react-router-dom";

export const ResetPassword: React.FC = () => {
  const [password, setPassword] = React.useState("");
  const params = useParams();

  const reset = useRequestState(resetPassword, null);
  const notify = useNotify();
  const navigate = useNavigate();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (params.token) {
      reset
        .fetch(params.token, password)
        .then(() => navigate("/login"))
        .catch((error) => {
          notify(error.response?.data?.message || error.message);
        });
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <Box marginY="1em">
          <TextField
            name="password"
            label={"Password"}
            type="password"
            value={password}
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
          />
        </Box>

        <Button variant="contained" type="submit" color="primary" fullWidth>
          {reset.loading && <CircularProgress size={25} thickness={2} />}
          Set New Password
        </Button>
      </form>
    </AuthLayout>
  );
};
