import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { ResponsiveContainer, Tooltip, Treemap } from "recharts";

import { ChartContainer } from "./container";
import { formatNumber } from "../../utils/currency";

export const TreemapChart = ({
  request,
  color = "green",
  format = (d) => d,
  nameKey = "name",
  valueKey = "count",
  colorKey,
  ...props
}) => {
  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { stats } }) => {
        return (
          <ResponsiveContainer width={"100%"} height={300}>
            <Treemap
              data={stats}
              nameKey={nameKey}
              dataKey={valueKey}
              stroke="#fff"
              ratio={4 / 3}
              isAnimationActive={false}
              content={
                <CustomContent
                  nameKey={nameKey}
                  colorKey={colorKey}
                  defaultColor={color}
                />
              }
            >
              <Tooltip
                content={
                  <CustomTooltip
                    nameKey={nameKey}
                    valueKey={valueKey}
                    colorKey={colorKey}
                  />
                }
              />
            </Treemap>
          </ResponsiveContainer>
        );
      }}
    </ChartContainer>
  );
};

const CustomContent = (props) => {
  const {
    depth,
    x,
    y,
    width,
    height,
    nameKey,
    [nameKey]: name,
    colorKey,
    [colorKey]: color,
  } = props;

  const maxWordLength = width / 7;
  const truncatedName =
    name?.length > maxWordLength
      ? name.slice(0, maxWordLength - 3) + "..."
      : name;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: color || props.defaultColor,
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {width > 50 && height > 50 ? (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={12}
        >
          {truncatedName}
        </text>
      ) : null}
    </g>
  );
};

const CustomTooltip = ({ active, payload, nameKey, valueKey }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box padding={1}>
          <Typography>
            {`${payload[0].payload[nameKey]} : ${formatNumber(
              payload[0].payload[valueKey],
              2
            )}`}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};
