export const superAdmin = "super-admin";
export const admin = "admin";
export const surveyor = "surveyor";

export const roles = [
  { id: superAdmin, name: "Super Admin" },
  { id: admin, name: "Admin" },
  { id: surveyor, name: "Surveyor" },
];

export const roleMapper = {
  "super-admin": "Super Admin",
  admin: "Admin",
  surveyor: "Surveyor",
};
