import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  CheckboxGroupInput,
  usePermissions,
  BooleanInput,
} from "react-admin";
import { roles } from "../roles";
import { userPermissions } from "./constants";
import { Conditionally } from "../components/conditionally";

export const UserCreate = () => {
  return (
    <Create title={"Register User"}>
      <Form />
    </Create>
  );
};

export const UserEdit = () => {
  return (
    <Edit title="Edit User">
      <Form />
    </Edit>
  );
};

export const Form = ({ ...props }) => {
  const { permissions: user } = usePermissions();
  return (
    <SimpleForm {...props}>
      <TextInput source="name" fullWidth validate={[required()]} />

      <TextInput source="email" fullWidth validate={[required()]} />

      <TextInput source="phone" fullWidth />

      <SelectInput
        source="role"
        choices={roles}
        fullWidth
        validate={[required()]}
      />

      <Conditionally
        when={user?.permissions?.user?.includes("suspend")}
        render={() => <BooleanInput source="suspended" />}
      />

      <CheckboxGroupInput
        source="permissions.user"
        label="User Permissions"
        choices={userPermissions}
      />
    </SimpleForm>
  );
};
