import {
  SelectInput,
  Pagination as RaPagination,
  TextInput,
  ListBase,
  FilterForm,
  FilterButton,
  CreateButton,
} from "react-admin";
import { roles } from "../roles";
import { MobileListGrid } from "../components/mobile-list-grid";
import { Box, Stack, Typography } from "@mui/material";

export const UserList = () => (
  <ListBase
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ suspended: false }}
  >
    <Toolbar />
    <MobileListGrid
      link={(record: any): any => `/users/${record.id}/edit`}
      content={(record: any) => <Typography variant="body1">{record.name}</Typography>}
      metadata={(record: any) => (
        <Typography variant="caption" color={"primary"}>{record.role}</Typography>
      )}
    />
    <Pagination />
  </ListBase>
);

const Pagination = () => (
  <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={50} />
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={userFilters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={userFilters} />
        <CreateButton />
      </Box>
    </Stack>
  );
};

const userFilters = [
  <TextInput label="Name" source="search.name" variant="outlined" />,
  <TextInput label="Email" source="search.email" variant="outlined" />,
  <SelectInput label="Role" source="role" choices={roles} variant="outlined" />,
  <SelectInput
    label="Status"
    source="suspended"
    choices={[
      { id: false, name: "Active" },
      { id: true, name: "Suspended" },
    ]}
    variant="outlined"
  />,
];
