export const questionFormats = [
  "Text",
  "Multiline Text",
  "Number",
  "Radio Buttons",
  "Checkboxes",
  "Dropdown",
  "Date",
  "Time",
  "Date Time",
  "Autocomplete",
].map((id) => ({ id, name: id }));

export const selectionFields = [
  "Radio Buttons",
  "Checkboxes",
  "Dropdown",
  "Autocomplete",
];
