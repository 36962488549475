import * as React from "react";
import { useLogin, useNotify } from "react-admin";
import { Typography, Button, TextField, Grid } from "@mui/material";

import { Link } from "react-router-dom";
import { AuthLayout } from "./layout";

export const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = useLogin();
  const notify = useNotify();

  React.useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    login({ email, password }).catch((error) => {
      notify(error.response?.data?.message || error.message, { type: "error" });
    });
  }

  return (
    <>
      <AuthLayout>
        <Grid
          component="form"
          container
          onSubmit={handleSubmit}
          direction={"column"}
          spacing={2}
        >
          <Grid item>
            <TextField
              autoFocus
              name="email"
              label={"Email"}
              value={email}
              fullWidth
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              name="password"
              label={"Password"}
              type="password"
              value={password}
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
            />
          </Grid>

          <Grid item>
            <Button variant="contained" type="submit" color="primary" fullWidth>
              {/* {isLoading && <CircularProgress size={25} thickness={2} />} */}
              Sign In
            </Button>
          </Grid>

          <Grid item>
            <Typography
              component={Link}
              to="/forgot"
              color="primary"
              textAlign="center"
              variant="body1"
              display={"block"}
              style={{
                textDecoration: "none",
              }}
            >
              Forgot Password?
            </Typography>
          </Grid>
        </Grid>
      </AuthLayout>
    </>
  );
};
