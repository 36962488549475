import { AxiosInstance, AxiosRequestConfig } from "axios";
import { stringify } from "qs";
import { HttpError, DataProvider } from "react-admin";

export function createRestProvider(client: AxiosInstance): DataProvider {
  function execute(request: AxiosRequestConfig) {
    return client
      .request({ ...request, url: `/portal/${request.url}` })
      .then((response) => response?.data)
      .catch((error) => {
        if (error.response?.data?.message) {
          return Promise.reject(
            new HttpError(
              error.response.data.message,
              error.response.status,
              error.response.data
            )
          );
        }

        return Promise.reject(new HttpError(error.message, 500));
      });
  }

  return {
    getList(resource, params) {
      return execute({
        method: "GET",
        url: `${resource}?${stringify({
          ...params.pagination,
          ...params.sort,
          filter: params.filter,
        })}`,
      });
    },

    getOne(resource, params) {
      return execute({ method: "GET", url: `${resource}/${params.id}` });
    },

    getMany(resource, params) {
      const { ids } = params;

      return execute({
        method: "GET",
        url: `${resource}?${stringify({ ids })}`,
      });
    },

    getManyReference(resource, params) {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        field,
        order,
        page,
        perPage,
        filter: {
          ...params.filter,
          [params.target]: params.id,
        },
      };

      return execute({ method: "GET", url: `${resource}?${stringify(query)}` });
    },

    create(resource, params) {
      return execute({ method: "POST", url: resource, data: params.data });
    },

    update(resource, params) {
      return execute({
        method: "PUT",
        url: `${resource}/${params.id}`,
        data: params.data,
      });
    },

    updateMany(resource, params) {
      const { ids, data } = params;

      return execute({
        method: "PUT",
        url: `${resource}?${stringify({ ids })}`,
        data,
      });
    },

    delete(resource, params) {
      return execute({ method: "DELETE", url: `${resource}/${params.id}` });
    },

    deleteMany(resource, params) {
      const { ids } = params;

      return execute({
        method: "DELETE",
        url: `${resource}?${stringify({ ids })}`,
      });
    },
  };
}
